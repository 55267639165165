/* @font-face {
  font-family: "gilroy300";
  src: url(fonts/Gilroy-Light\ 300.ttf);
}  */
@font-face {
  font-family: "gilroy700";
  src: url(fonts/Gilroy-Bold\ 700.ttf);
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif;
  scrollbar-width: thin;
}

*::-webkit-scrollbar{
  width: 8px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb{
  background-color: #9e9c9c;
  border-radius: 5px;
}


.app{
  min-height: 98vh;
  border: 8px solid #444444;
  margin: auto;
  /* padding: 5px; */
  background-color: #f4f4f4;
}

/* @media (max-width: 700px) {
 .app{
   width: 100%;
 }
} */