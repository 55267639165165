.question{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.single-question{
    width: 100%;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: space-around;
    padding: 20px;
    margin-top: 10px;
}

.options{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    margin: 10px;
}
.single-option{
    width: 20%;
    height: 50px;
    padding: 15px 20px;
    margin: 10px;
    box-shadow: 0 0 2px black;
    cursor: pointer;
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
}

.download-button{
    display: flex;
    height: 45vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */

}

@media (max-width:614px) {
    .single-option{
        width: 100%;
    }    
}

