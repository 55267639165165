.subtitle{
    font-size: 30px;
    text-shadow: 4px 4px 2px rgba(0, 0, 0,0.15);
    padding: 5px 10px;
}

.quiz{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.quizInfo{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    margin: 10px;
    text-shadow: 4px 4px 2px rgba(0, 0, 0,0.1);
    font-weight: 700;
}
@media (max-width:614px){
    .quizInfo{
        flex-direction: column;
        align-items: center;
    }
}

.deviceInfo1{
    display: flex;
    flex-direction: column;
}

.deviceInfo2{
    display: flex;
    flex-direction: column;
}

