a{
    text-decoration: inherit;
    color: inherit;
}

.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    top: 0px;
    z-index: 10;
    background-color: #f4f4f4;
    padding: 5px;
}
.title{
    font-size: 5vw;
    cursor: pointer;
    background-image: linear-gradient(to right, #d73925,#d24b39);
    color: #f4f4f4;
    padding: 0 20px;
    font-family: "gilroy700",sans-serif;
}

@media (max-width:614px) {
    .title{
        font-size: 30px;
    }
}

.divider{
    margin: 10px;
    width: 100%;
    background-color: #d73925;
    display: block; 
    height: 1px;
    border: 0; 
    border-top: 1px solid #d73925;
    margin: 1em 0;
    padding: 0;
}