.content{
    display: flex;
    justify-content: space-around;
}

.form-container{
    width:55%;
    padding:10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 300;
}

.banner{
    width: 45%;
    align-self: center;
    padding: 8px;
    /* border: 1px solid red; */
}

.form-select{
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 80%;
    flex: 0.8;
    justify-content: space-evenly;
    /* border: 1px solid red; */

}
.input-form-select{
    display:flex;
    flex-direction:row;
    align-items:center;
    margin-bottom:25px;
}
.error-message{
    color: red;
    font-size: 23px;
    font-family:  'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }


@media (max-width:800px){
    .content{
        flex-direction: column-reverse;
        align-items: center;
    }
    .form-container{
        width: 100%;
    }
    .banner{
        width: 85%;
        padding: 0;
        padding-top: 20px;
    }
    .form-select{
        width: 85%;
        padding: 0;
    }
    
}


.MuiFormLabel-root.Mui-focused{
    color: #542915 !important;
    font-weight: 700 ;
} 

.MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline {
    border-color:  #542915 !important;
}

.MuiButton-containedPrimary {
    background-color: #d73925 !important;
}

.MuiButton-containedPrimary:hover {
    background-color: #942e20 !important;
}